import React from "react";
import { Component } from "react";
import { Prompt, withRouter } from "react-router-dom";
import { connect } from "react-redux";
//import { actionCreators as Login } from '../store/Login';
//import { ApplicationState } from "../store";
import $ from "jquery";
import "../../css/DashBoard.css";
import "../../css/assessment.css";
import { actionCreators as AssessmentActionCreator } from "../../store/Assessment";
import MCQ from "../questions/MCQComponent";
import TrueOrFalse from "../questions/TrueOrFalse";
import OrderComponent from "../questions/OrderComponent";
import MatchQComponent from "../questions/MatchQComponent";
import EssayComponent from "../questions/EssayComponent";
import AudioComponent from "../questions/AudioComponent";
import LabelQComponent from "../questions/LabelQComponent";
import ImageHighlightComponent from "../questions/ImageHighlightComponent";
import FileUploadEnhanced from "../questions/FileUploadEnhanced";
import FillTheBlank from "../questions/FillTheBlank";
import VideoComponent from "../questions/VideoComponent";
import "react-responsive-modal/styles.css";
import { makeId } from "../../helpers/func";
import Loader from "react-loader-spinner";
import Swal from "sweetalert2";
import MultiStepFunc from "../../hooks/MultiStepFunc";
import Cookies from "js-cookie";
import axios from "axios";
import { BASE_URL } from "../../variables/api";

import ProcessCardSingleState from "../questions/ProcessCardSingleState";
import { MathJax } from "better-react-mathjax";

const mapDispatchToProps = (dispatch: (arg0: any) => any) => ({
    onSaveQuestionHandler: (data: any) =>
        dispatch(AssessmentActionCreator.saveQuestion(data)),
    GenerateQuestion: (data: any) =>
        dispatch(AssessmentActionCreator.GenerateQuestion(data)),
    AddAssessment: (data: any) =>
        dispatch(AssessmentActionCreator.AddAssessment(data)),
    StudentSubmiteExam: (data: any) =>
        dispatch(AssessmentActionCreator.StudentSubmiteExam(data)),
    StudentSubmiteExamPerQuestion: (data: any) =>
        dispatch(AssessmentActionCreator.StudentSubmiteExamPerQuestion(data)),
    StudentSilentSubmiteExam: (data: any) =>
        dispatch(AssessmentActionCreator.StudentSilentSubmiteExam(data)),
    StudentStartExam: (data: any) =>
        dispatch(AssessmentActionCreator.StudentStartExam(data)),
    UpdateGradingAssessment: (data: any) =>
        dispatch(AssessmentActionCreator.UpdateGradingAssessment(data)),

    GetStudentAssessment: (id: any) =>
        dispatch(AssessmentActionCreator.GetStudentAssessment(id)),

    resetGotData: () =>
        dispatch(AssessmentActionCreator.resetGotData()),

});

class StudentAssessment extends Component<any, any> {
    buttonRef: any;
    examContainerRef: any;
    state = {
        isInite: true,
        exam_start: false
    };
    constructor(props: any) {
        super(props);
        this.buttonRef = React.createRef();
        this.examContainerRef = React.createRef();
    }

    modalContent: any = null;
    examMode = "examMode";
    handleFullScreenClick() {
        const elem: any = document.documentElement;
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
        }
    }
    handleBeforeUnload(event: any) {
        this.submitExam();
        event.preventDefault();
        return 'are you sure'
        // event.returnValue = ''; 
    }


    componentDidMount() {

        $("#preloader").hide();
        this.props.resetGotData()
        let id = this.props.location.search.split("=")[1];
        this.props.GetStudentAssessment(id)
        // window.beforeunload = function () { localStorage.setItem("fexam", id); handleUnload() };
        // window.addEventListener('beforeunload', this.handleBeforeUnload);
        document.addEventListener("fullscreenchange", (e) => {
            e.preventDefault()
            if (document.fullscreenElement) {
            } else {
                if (this.props.assessment.issecured === "1") {
                    Swal.fire({
                        title: 'Warning?',
                        html: `You are leaving full screen mode this will cause the exam to be submitted<br>click back to full screen to avoid that`,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, Get Back To Full Screen!'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.handleFullScreenClick()
                        } else {
                            this.submitExam();
                        }
                    })
                }
            }
        })
    }


    componentWillUnmount() {
        // window.removeEventListener('beforeunload', this.handleBeforeUnload);
    }

    componentDidUpdate() {
        const assessment = this.props.assessment;
        let id = this.props.location.search.split("=")[1];
        let ExamStatus = localStorage.getItem("key");
        if (assessment) {
            if (assessment.status === "Submitted") {
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    html: "You have submitted this test before!<br>Please close the browser.",
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    showCancelButton: false
                }).then((x: any) => {
                    // this.examContainerRef.current.classList.remove('hide-element')

                    // window.opener = null;
                    // window.open("https://ivyis.org", "_self", "");
                    // window.close();
                })

            } else if (assessment.status === "Started" && this.props.assessment.issecured === "1" && this.props.isInite) {
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    html: `You have started this assessment before and this assessment is secured 
                    so if you want to open again ask your teacher `,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    showCancelButton: false
                }).then((x: any) => {
                    // this.examContainerRef.current.classList.remove('hide-element')

                    // window.opener = null;
                    // window.open("https://ivyis.org", "_self", "");
                    // window.close();
                })
            } else if (assessment.status === "Pending") {

                const examInfoHTML = `<h4>
                You are taking exam:  <span style="font-weight:bold;">${assessment?.name}</span>
                </h4><br>`
                const isSecuredHTML = assessment?.issecured === "1" ?
                    `<h4>This is a secured exam any attempt to leave the page will be detected and will submit exam right away</h4>
                    <br>`
                    : ``
                const isTimedHTML = assessment?.istimed === "1" ?
                    `<h4>This is a timed exam</h4>
                    <br>
                    <h4>Duration : <span style="font-weight:bold;">${assessment?.duration} minutes</span></h4>
                    <br>`
                    : ``;
                const numberOfQuestions = assessment?.questions.length ?
                    `<h4>Number Of Questions : <span style="font-weight:bold;">${assessment?.questions.length}  Questions</span></h4>
                    <br>`
                    : ``;
                Swal.fire({
                    title: 'Are you ready?',
                    html: `${examInfoHTML}${isSecuredHTML}${isTimedHTML}${numberOfQuestions}`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, I am ready!',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.handleFullScreenClick()
                        if (ExamStatus === null || (ExamStatus !== id && (this.getMinutes() === 0 && this.getSeconds() === 0))) {
                            localStorage.setItem("key", id);
                            if (assessment?.istimed === "1") {
                                this.SetTimer(parseInt(assessment.duration));
                            }
                        } else {
                            let fexam = localStorage.getItem("fexam");
                            if (fexam === id) {
                                localStorage.setItem("fexam", "");
                            }
                        }
                        if (this.props.assessment.issecured === "1") {
                            window.addEventListener('beforeunload', this.handleBeforeUnload);
                            document.addEventListener("visibilitychange", (e) => {
                                e.preventDefault()
                                if (document.visibilityState === "visible") {

                                } else {
                                    if (assessment.status != "Pending") {
                                        window.opener = null;
                                        window.close();
                                    } else {
                                        this.submitExam();
                                    }
                                }
                            });
                        }
                        const data = {
                            id: this.props.assessment.id,
                        };
                        this.props.StudentStartExam(data);
                        this.examContainerRef.current.classList.remove('hide-element')
                        this.setState({
                            exam_start: true
                        })
                    } else {
                        window.opener = null;
                        window.close();
                    }
                })

            } else if (assessment.status === "Graded") {
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    html: "You have submitted this test before!<br>Please close the browser.",
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    showCancelButton: false
                }).then((x: any) => {
                    // this.examContainerRef.current.classList.remove('hide-element')

                    // window.opener = null;
                    // window.open("https://ivyis.org", "_self", "");
                    // window.close();

                })

            }


        }
    }

    onSaveQuestion = (q: any) => {
        this.props.onSaveQuestionHandler(q);
    };

    submitExam = (val: any = "nothing") => {
        Swal.fire({
            title: "Submit Assessment",
            html: "Are you sure?<br/> Please note that, this action can not be undone!!",
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonColor: '#3085d6',
            // cancelButtonColor: '#d33',
        }).then((x: any) => {
            if (x.isConfirmed) {
                Swal.showLoading();
                const data = {
                    id: this.props?.assessment.id,
                    questions: this.props.questions,
                };
                this.props.StudentSubmiteExam(data);
                localStorage.setItem("fexam", "");
                localStorage.setItem("key", "");
                Swal.showLoading();
            } else {
                //this.handleFullScreenClick()

            }
        });

    };

    submitExamButton = (event: any) => {
        // event.preventDefault();
        Swal.showLoading();
        const data = {
            id: this.props.assessment.id,
            questions: this.props.questions,
        };
        this.props.StudentSubmiteExam(data);
        localStorage.setItem("fexam", "");
        localStorage.setItem("key", "");

    };

    silentSubmitExam = () => {
        Swal.showLoading();
        const data = {
            id: this.props.assessment.id,
            questions: this.props.questions,
        };
        this.props.StudentSubmiteExam(data);
        localStorage.setItem("fexam", "");
        localStorage.setItem("key", "");
    };

    submitPerQuestionExam = () => {
        Swal.showLoading();
        const data = {
            id: this.props.assessment.id,
            questions: this.props.questions,
        };
        this.props.StudentSubmiteExamPerQuestion(data);
    };
    removeQuest = (e: any) => {
        this.props.DeleteQuestion(e.id)
    }
    generateNewQuestion = (student_exam_id: any) => {
        if (this.props.questions.length == 5) {
            document
                .exitFullscreen()
            Swal.fire({
                icon: "success",
                title: "Finished",
                html: "Thank you for taking the placement test",
                allowOutsideClick: false,
                showConfirmButton: false,
                showCancelButton: false,
                // confirmButtonText :"End"
            }).then((x: any) => {
                // this.examContainerRef.current.classList.remove('hide-element')
            })
        } else {
            Swal.showLoading();
            axios.post(BASE_URL + "admin/generatequestion.php", { id: student_exam_id }).then((res) => {
                Swal.close();

                this.props.onSaveQuestionHandler(res.data.data)

            }).catch((err) => {
                Swal.fire(err.response.data.msg, "", "error");
            })
        }

    }
    getMinutes = () => {
        let m = $("#TMinutes").text();
        return parseInt(m) ?? 0;
    }
    getSeconds = () => {
        let m = $("#TSeconds").text();
        return parseInt(m) ?? 0;
    }
    countDown = () => {
        let minutes = this.getMinutes();
        let seconds = this.getSeconds();
        seconds = seconds - 1;
        if (seconds === -1) {
            minutes = minutes - 1;
            if (minutes === -1) {
                this.silentSubmitExam();
                $("#TMinutes").text(this.TwoDF(0));
                $("#TSeconds").text(this.TwoDF(0));
                return;
            }
            seconds = 59;
        }
        if (minutes === 0) {
            $("#TSeconds").css("color", "red");
        }
        $("#TMinutes").text(this.TwoDF(minutes));
        $("#TSeconds").text(this.TwoDF(seconds));
        if (this.props.submitAssessmentMsg == "") {
            setTimeout(() => this.countDown(), 1000);
        }
    }

    TwoDF = (n: number) => {
        if (n <= 9) {
            return `0${n}`;
        } else {
            return `${n}`;
        }
    }
    SetTimer = (t: number) => {
        $("#TMinutes").text(this.TwoDF(t));
        $("#TSeconds").text("01");
        this.countDown();

    }
    render() {
        if (this.props.loading) {
            Swal.close();
        }
        //examMode
        //gradingModeTeacher
        //gradingModeStudent
        const editQuestionsRenderer = (s: any, q: any, index: any) => {
            q.mode = this.examMode;
            switch (s) {
                case "MCQ":
                    return (
                        <MCQ
                            quest={q}
                            index={index}
                            onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
                        />
                    );
                    return null;
                case "OrderComponent":
                    return (
                        <OrderComponent
                            quest={q}
                            index={index}
                            onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
                        />
                    );
                    return null;
                case "MatchQComponent":

                    return (
                        <MatchQComponent
                            quest={q}
                            index={index}
                            onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
                        />
                    );
                    return null;
                case "ProcessCard":

                    return (
                        <ProcessCardSingleState
                            type={"ProcessCard"}
                            quest={q}
                            index={index}
                            onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
                        />
                    );
                    return null;
                case "ProductCard":

                    return (
                        <ProcessCardSingleState
                            type={"ProductCard"}
                            quest={q}
                            index={index}
                            onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
                        />
                    );
                    return null;
                case "LabelQComponent":
                    return (
                        <LabelQComponent
                            quest={q}
                            index={index}
                            onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
                        />
                    );
                    return null;
                case "TrueOrFalse":
                    return (
                        <TrueOrFalse
                            quest={q}
                            index={index}
                            onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
                        />
                    );
                    return null;
                case "EssayComponent":
                    return (
                        <EssayComponent
                            quest={q}
                            index={index}
                            onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
                        />
                    );
                    return null;
                case "VideoComponent":
                    q.showRecordingOpt = true
                    return (
                        <VideoComponent
                            quest={q}
                            index={index}
                            onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
                        />
                    );
                    return null;
                case "AudioComponent":
                    return (
                        <AudioComponent
                            quest={q}
                            index={index}
                            onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
                        />
                    );
                    return null;
                case "FileUploadEnhanced":
                    return (
                        <FileUploadEnhanced
                            quest={q}
                            index={index}
                            onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
                        />
                    );
                    return null;
                case "FillTheBlank":
                    return (
                        <FillTheBlank
                            quest={q}
                            index={index}
                            onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
                        />
                    );
                    return null;
                case "ImageHighlight":

                    return (
                        <ImageHighlightComponent
                            quest={q}
                            index={index}
                            onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
                        />
                    );
                    return null;
                default:
                    return (
                        null
                    );
            }
        };

        let examsArr: any = [];

        if (this.state.exam_start) {

            examsArr = this.props.questions?.map((q: any, index: number) => {
                return (
                    <React.Fragment key={index}>

                        {editQuestionsRenderer(q.type, q, index + 1)}
                    </React.Fragment>
                );
            })
        }
        //this.props.questions.find
        const onConfirm = (isPassed:any) => {
             // The message to send to the parent
             const message = { type: 'FROM_IFRAME', data: 'Finished' , isPassed : isPassed };

             // Send the message to the parent window
             parent.postMessage(message, '*'); // '*' allows communication with any origin
             // It's recommended to replace '*' with the specific origin of the parent for security reasons
            // window.close();
        }


        let MSG = null;
     
        if (this.props.submitAssessmentMsg !== "") {
            Swal.fire({
                icon: "success",
                title: "Success",
                text: this.props.submitAssessmentMsg,
                allowOutsideClick: false,

                showConfirmButton: true
            }).then(() => {

                onConfirm(this.props.pass);
            })
        }


        if (this.props.studentAssessmentLoading != true && this.props.assessment === null) {
            return <div className="text-center">
                <h3>
                    No Valid Access!, Please Click on the Assessment link from the LMS.
                </h3>
            </div>
        }

        return (

            this.props.studentAssessmentLoading != true ? (
                <MathJax>
                <div>
                    <div className="position-fixed" style={{ fontSize: "55px", fontFamily: "Digital" }}>
                        <span id="TMinutes" style={{ borderRadius: "8px", background: "#c0c7cd" }}>00</span>
                        <span>:</span>
                        <span id="TSeconds" style={{ borderRadius: "8px", background: "#c0c7cd" }}>00</span>
                    </div>
                    <div className=" d-flex d-md-block flex-nowrap wrapper ">
                        <div ref={this.examContainerRef} className="hide-element col-md-9 float-center mx-auto pl-md-2 pt-2 main">
                            <div>
                                <div className="col-12 mx-auto align-items-center rounded  text-white">

                                    <h3 className="text-shadow mt-2 ml-3 text-ivyblue">{this.props.assessment?.name} : </h3>



                                    {this.props.assessment?.etype == "benchmark" ? (
                                        <>
                                            {examsArr[examsArr?.length - 1]}
                                            <div style={{ marginTop: "1rem", display: "flex", gap: ".5rem", justifyContent: "flex-end", }}>
                                                <h3 onClick={() => { this.generateNewQuestion(this.props.assessment?.id) }} className="btn btn-success">Next</h3>
                                            </div>
                                        </>
                                    ) : (

                                        this.props.submitAssessmentMsg === "" ?
                                            <MultiStepFunc examsArr={examsArr}
                                                buttonRef={this.buttonRef}
                                                submitPerQuestion={this.submitPerQuestionExam}
                                            >
                                            </MultiStepFunc>
                                            : <div>
                                                <h2>{this.props.submitAssessmentMsg}</h2>
                                            </div>

                                    )}



                                    {this.props.assessment?.status === "Started" && this.props.assessment.etype != "benchmark" ?

                                        <div className="text-center my-5 ">
                                            <button
                                                className="btn btn-warning text-white w-100"
                                                onClick={this.submitExam}
                                                ref={this.buttonRef}
                                            // disabled={true}
                                            >
                                                Submit exam
                                            </button>
                                        </div>
                                        : null

                                    }


                                </div>

                            </div>
                        </div>
                    </div>
                </div></MathJax>


            ) : (<MathJax>
                <div className="d-flex justify-content-center align-items-center mt-5">
                    <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} />
                </div></MathJax>
            )

        );
        // }
    }
}
function mapStateToProps(state: any) {
    return {
        questions: state.Assessment.questions,
        assessment: state.Assessment.assessment,
        isInite: state.Assessment.isInite,
        loading: state.Assessment.loading,
        assessmentMsg: state.Assessment.assessmentMsg,
        addAssessmentloading: state.Assessment.addAssessmentloading,
        grades: state.Assessment.grades,
        subjects: state.Assessment.subjects,
        submitAssessmentMsg: state.Assessment.submitAssessmentMsg,
        pass : state.Assessment.pass
    };
}

export default withRouter(
    connect(
        mapStateToProps, // Selects which state properties are merged into the component's props
        mapDispatchToProps // Selects which action creators are merged into the component's props
    )(StudentAssessment as any)
);
