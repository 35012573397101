let domain = ""
if(window.location.origin.includes("iatseg") ){
    domain = "iatseg.org"
}else if(window.location.origin.includes("ics")) {
    domain = "ics.ivyis.org"
   }else if(window.location.origin.includes("ivyis")) {
 domain = "ivyis.org"
}else {
    domain = "localhost"
}

export const BASE_URL = domain == "localhost" ? "https://localhost/assessments_platform_lwe/backend/" : "https://exam.lwe.ai/backend/" 
export const REACT_APP_LOGIN = domain == "localhost" ? "https://localhost/assessments_platform_lwe/backend/login.php" : ""
export const REACT_APP_LOGOUT = domain == "localhost" ? "https://localhost/assessments_platform_lwe/backend/logout.php" :  ""


//NODE_ENV === 'development' ? "http://localhost/Students_Affairs/backend/" : 'https://sis.ivyis.org/backend/';