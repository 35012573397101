import React, { useEffect, useRef, useState } from "react";
import "../../css/DashBoard.css";
import RichBox from "./smallComponents/RichBoxComponent";
import { ReactPainter } from 'react-painter';
import { handleCount } from '../../helpers/validation';
import { MapSecondsToTime, base64ToBlob, handleFileUpload2s3, imageUrlToBase64, makeId, requestWithAxios } from "../../helpers/func";
import Swal from "sweetalert2";
import DifficultLevel from "./smallComponents/DifficultLevel";
import { BASE_URL } from "../../variables/api";
import axios from "axios";




// editingMode
// examMode
// gradingModeTeacher
// gradingModeStudent


const ImageHighlight = (props: any) => {
  const [state, setState] = useState<any>({
    id: makeId(10),
    type: "ImageHighlight",
    show_objectives: false,
    objectives: "",
    feedback: "",
    mode: "",
    total_mark: "",
    score: "",
    author_note: "",
    header: '',
    qst_image: '',
    resetCanvas: 0,
    calibration :"",
    qst_image_after_drawing: "",
    msg: '',
    headertxt: '',
    required: true,
    difficult: "Basic Level",
    cognitive: "Remembering",
    // this flag is used to see if we have to resave the img
    hasChangedImgFlag: false,
  })

  let count = useRef<any>();
  const [spenttime, setSpentTime] = useState(0);
  const [intervalId, setIntervalId] = useState<any>(null);
  useEffect(() => {
    if (props.quest != undefined) {
      let Question = { ...props.quest };
      if (Question.mode == "examMode") {
        axios.get(`${BASE_URL}/student/getspenttime.php?studentexamquestion_id=${Question.id}`).then((response) => {
          setSpentTime(response.data.data);
          const intervalId = setInterval(() => {
            setSpentTime((prev: any) => ++prev);
            // props.onSaveQuestion({ ...state })
          }, 1000);
          setIntervalId(intervalId)
        }).catch((err) => {
          setSpentTime(0);
          const intervalId = setInterval(() => {
            setSpentTime((prev: any) => ++prev);
            // props.onSaveQuestion({ ...state })
          }, 1000);
          setIntervalId(intervalId)

        });

      }

      Swal.showLoading();
      if (Question.qst_image?.includes("https")) {
        Swal.showLoading();
        if (Question.qst_image?.includes(".txt")) {
          fetch(Question.qst_image).then((res: any) => {
            Swal.showLoading();
            res.text().then((data: any) => {

              Question.qst_image = data;
              if (Question.qst_image_after_drawing?.includes("https") && Question.qst_image_after_drawing?.includes(".txt")) {
                fetch(Question.qst_image_after_drawing).then((res: any) => {
                  Swal.showLoading();
                  res.text().then((data: any) => {

                    Question.qst_image_after_drawing = data;
                    setState(Question)
                    Swal.close()
                  })

                }).catch((err: any) => {
                  Swal.fire({
                    icon: "error",
                    text: "Image will not appear if you have problem with internet connection"
                  });
                  setState(Question)
                })
              } else if (Question.qst_image_after_drawing?.includes("https")) {
                imageUrlToBase64(Question.qst_image_after_drawing).then((res: any) => {
                  Question.qst_image_after_drawing = res;
                  setState(Question)
                  Swal.close()
                }).catch((err: any) => {
                  Swal.fire({
                    icon: "error",
                    text: "Image will not appear if you have problem with internet connection"
                  });
                  setState(Question)
                })
              } else {
                setState(Question)
              }


              Swal.close()
            }).catch((err: any) => {
              Swal.fire({
                icon: "error",
                text: "Image will not appear if you have problem with internet connection"
              })
            })

          }).catch((err) => {
            Swal.fire({
              icon: "error",
              text: "Image will not appear if you have problem with internet connection"
            })
            setState(Question)

          })
        } else {
          imageUrlToBase64(Question.qst_image).then((res: any) => {
            Question.qst_image = res;

            if (Question.qst_image_after_drawing?.includes("https")) {
              imageUrlToBase64(Question.qst_image_after_drawing).then((res: any) => {
                Question.qst_image_after_drawing = res;
                setState(Question)
                Swal.close()
              }).catch((err: any) => {
                Swal.fire({
                  icon: "error",
                  text: "Image will not appear if you have problem with internet connection"
                });
                setState(Question)
              })
              Swal.close()
            } else {
              setState(Question)
              Swal.close()
            }
          }).catch((err: any) => {
            Swal.fire({
              icon: "error",
              text: "Image will not appear if you have problem with internet connection"
            });
            setState(Question)
          })
        }


      } else {
        setState(Question)
        Swal.close()
      }






    } else {
      setState((prev: any) => ({ ...prev, mode: "editingMode" }))
      Swal.close()
    }
  }, []);

  useEffect(() => {
    return () => {
      if (state.mode == "examMode" && state.studentexam_id !== undefined && state.studentexam_id != null) {
        clearInterval(intervalId)
        requestWithAxios(
          BASE_URL + "student/savespenttime.php",
          { spenttime: count.current.value, "studentexamquestion_id": state.id }
        );
      }
    }
  }, [intervalId])



  const onSaveQuestion = (updatedState: any) => {


    let require = handleCount(state.header);

    if (require) {
      Swal.fire({
        icon: 'error',
        text: ' Question Header is required',
      }).then(() => {

      })
    } else if (state.qst_image == "") {
      Swal.fire({
        icon: 'error',
        text: ' You have to upload image',
      }).then(() => {

      })
    } else if ((state.total_mark.length == 0) || (state.total_mark == ".")) {
      Swal.fire({
        icon: 'error',
        text: 'Total mark is required',
      }).then(() => {

      })
    } else {

      // if (state.mode == "editingMode") 
      if (state.hasChangedImgFlag) {

        const imageBlob = base64ToBlob(state.qst_image, 'image/png');
        var formData = new FormData();
        formData.append('file', imageBlob, "imagehighlight.png");
        Swal.showLoading();
        handleFileUpload2s3(formData.get('file'), state.id).then((res: any) => {
          let updatedState = { ...state, qst_image: res, hasChangedImgFlag: false }
          props.onSaveQuestion(updatedState)
          Swal.close();
        }).catch(() => {
          Swal.fire({
            icon: "error",
            text: "Image was not saved, please check your internet connection"
          })
        })
      } else {
        props.onSaveQuestion(updatedState)

      }

    }
  }




  // setHeader = (contents: any) => {
  //   header = contents;
  // };
  const resetcanvasfunc = () => {
    setState((prev: any) => ({
      ...prev, resetCanvas: Math.random(),
      qst_image_after_drawing: ""
    }))

  }
  const onSaveCanvas = async (imageDownloadUrl: any) => {

    Swal.showLoading();
    let blob = await fetch(imageDownloadUrl, { method: 'GET', mode: 'no-cors' }).then((r) => r.blob()).then((blobFile) => {
      let file = new File([blobFile], "fileNameGoesHere", {
        type: "image/png",
      });
      var reader: any = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        var base64data = reader.result;
        console.log(base64data)
        const imageBlob = base64ToBlob(base64data, 'image/png');
        var formData = new FormData();
        formData.append('file', imageBlob, "imagehighlight.png");
        handleFileUpload2s3(formData.get('file'), state.id).then((res: any) => {
          let updatedState = { ...state, qst_image_after_drawing: res, qst_image: props.quest.qst_image };
          onSaveQuestion(updatedState)
          setState((prev: any) => ({ ...prev, qst_image_after_drawing: base64data }));
          if (state.mode == "examMode" && state.studentexam_id !== undefined && state.studentexam_id != null) {
            axios.post(`${BASE_URL}/student/saveq.php`, updatedState).then((res: any) => {
              Swal.close();
              Swal.fire({
                icon: 'success',
                text: 'Question Saved Successfully',
              }).then(() => {

              })
            })
          }
        }).catch((err: any) => {
          Swal.fire({
            icon: 'error',
            text: 'Something wrong, Please check your internet and try again ',
          })
        })


      };
    });

  };
  const loadFromUrl = async (imageDownloadUrl: any) => {
    if ("" === imageDownloadUrl) {
      return;
    }
    try {
      Swal.showLoading();
      const data = await fetch(imageDownloadUrl);
      const blob = await data.blob();
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data: any = reader.result;
          var file = new Blob([base64data], { type: 'text/plain' });
          var formData = new FormData();
          formData.append('file', file, "imagehighlight.txt");
          handleFileUpload2s3(formData.get('file'), state.id).then((res: any) => {
            Swal.showLoading();
            if (res?.includes("https")) {
              Swal.showLoading();
              fetch(res).then((img64: any) => {
                Swal.showLoading();
                img64.text().then((data: any) => {
                  setState((prev: any) => ({ ...prev, qst_image: data, hasChangedImgFlag: true }));
                  Swal.close()
                }).catch((err: any) => {
                  Swal.fire({
                    icon: "error",
                    text: "Image will not appear if you have problem with internet connection"
                  })
                })
              }).catch((err) => {
                Swal.fire({
                  icon: "error",
                  text: "Image will not appear if you have problem with internet connection"
                })

              })
            } else {
              Swal.close()
            }
          }).catch((err: any) => {
            Swal.fire({
              icon: 'error',
              text: 'Something wrong, Please check your internet and try again ',
            })
          })


          resolve(base64data);
          Swal.close()
        }
      });
    } catch (error) {
      Swal.close()

      //Failed to fetch
      let e: any = error;
      if (e.message === 'Failed to fetch') {
        setState((prev: any) => ({ ...prev, qst_image: "", hasChangedImgFlag: true }));
        Swal.fire("Error", "You can't use this image from this url! please choose another image from another url or upload the image directely from PC", "error")
      }
    }

  };
  const openAddFileInputHandler = () => {
    const fileInput = document.getElementById("addFile") as HTMLInputElement;
    fileInput.click();
  };

  const addFileHandler = (e: any) => {
    Swal.showLoading();
    const file: any = e.target.files[0];
    // Reset the input
    e.target.value = '';


    if (file && file.size > 0) {
      var reader: any = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        if (reader.result.split(":")[1].split("/")[0] === "image") {
          setState((prev: any) => ({ ...prev, qst_image: reader.result, hasChangedImgFlag: true }));


        }
      };
    }
    const ele = document.querySelector('#imageurl')! as HTMLInputElement;
    ele.value = '';
    Swal.close()
  };

  const handleCalibration = (e: any) => {
    let value = e.target.value;
    setState((prev: any) => ({ ...prev, calibration: value }))


  }


  return (
    // <div className="mx-5">
    <div className="">
      <div className="row rounded my-3 p-0 ll shadow bg-question">
        {state.mode == "examMode" && (
          <div className="row col-12">
            <div className="  pt-4 pl-4 col-4" style={{ color: props.color || "#fff", fontSize: "28px", paddingLeft: "10px", fontWeight: "bold" }}> Q {props.index}
            </div>
            <div className="  p-3 col-6 d-flex justify-content-end" style={{ color: props.color || "#fff", fontSize: "28px", paddingLeft: "10px", fontWeight: "bold" }}>
              <input type="hidden" ref={count} value={spenttime} />
              <div>
                Spent time : <label id="minutes">{MapSecondsToTime(Math.floor(spenttime / 60))}</label>:<label id="seconds">{spenttime > 0 ? MapSecondsToTime(+spenttime % 60) : "00"}</label>
              </div>
            </div>
          </div>
        )}
        <div className="col-12 row mx-auto align-items-center bg-question rounded p-3 text-white">
          <div className="col-12 row justify-content-between">
            {state.mode == "editingMode" ? (<h3 className="text-shadow"> Question Setup</h3>) : null}

            {/*<button
              className="btn bg-info text-white"
              onClick={stateToJsonGradingMode}
            >
              show
            </button>*/}
            <div className="d-flex flex-column">
              <div className="form-group d-flex align-items-center my-3">
                <span className="col-auto text-shadow ">
                  Total mark :
                </span>
                {state.mode === "editingMode" ? (
                  <input
                    type="number"
                    min={0}
                    className="col form-control"
                    name=""
                    id=""
                    value={state.total_mark}
                    onChange={(e) => {
                      if ((typeof +e.target.value == "number") || e.target.value == "" || e.target.value == ".") {
                        let total_mark = e.target.value;
                        setState((prev: any) => ({ ...prev, total_mark: total_mark }))

                      }
                    }
                    }
                  />
                ) : state.mode === "examMode" ||
                  state.mode === "gradingModeTeacher" ||
                  state.mode === "gradingModeStudent" ? (
                  <span className="text-shadow">{state.total_mark}</span>
                ) : null}
                {state.mode === "gradingModeTeacher" ? (
                  <>
                    <span className="col-auto border-left ml-2">
                      Score :
                    </span>
                    <input
                      type="number"
                      min={0}
                      className="col form-control"
                      name=""
                      id=""
                      value={state.score}
                      onChange={(e) => {
                        let score = e.target.value
                        let updatedState = { ...state, score }
                        onSaveQuestion(updatedState)

                        setState((prev: any) => ({ ...prev, score: score }))
                      }
                      }
                    />
                  </>
                ) : state.mode === "gradingModeStudent" ? (
                  <>
                    <span className="border-left ml-2 pl-2 text-shadow">
                      Score :  {state.score}
                    </span>
                  </>
                ) : null}
              </div>
            </div>
          </div>



          {
            ("editingMode" != state?.mode) ? null :
              <div className="col-12 mb-5 row justify-content-center align-items-center">
                <div
                  className="bg-white w-100 text-dark rounded align-items-center p-3"
                >
                  Highlight Image
                </div>
              </div>

          }







          <div className="col-12 mb-5 row justify-content-center align-items-center">
            {state.mode === "editingMode" ? (
              <div className="col-12 w-100 row justify-content-center align-items-center">
                <RichBox
                  onChange={(v: any) => { setState((prev: any) => ({ ...prev, header: v })) }}
                  onChangeTxt={(a: any) => { setState((prev: any) => ({ ...prev, headertxt: a })) }}
                  defaultValue={state.header}
                  height={"100%"}
                ></RichBox>
                <div className="mt-2 col-8 row justify-content-center">
                  <label className="align-self-start">Author Note </label>
                  <input
                    className="form-control align-self-stretch "
                    name=""
                    id=""
                    value={state.author_note}
                    onChange={(e) => {
                      let author_note = e.target.value;
                      setState((prev: any) => ({ ...prev, author_note: author_note }))

                    }

                    }
                  ></input>
                </div>
                <div className="mt-2 col-8 row">
                  <button className="btn bg-info text-white" onClick={() => { openAddFileInputHandler(); setState((prev: any) => ({ ...prev, qst_image: '' })); }}> upload img</button>
                  <input accept="image/*" id="addFile" multiple={false} hidden={true} type="file" onChange={addFileHandler} />
                  <label className="align-self-center ml-5 mr-4 mt-1" htmlFor="imageurl">OR Image Url:  </label>
                  <input
                    id="imageurl"
                    name="imageurl"
                    type="text"
                    className="col-8 form-control normal"
                    onChange={(e) => {
                      const tmp_url = e.target.value;
                      if (" " === tmp_url) {
                        e.target.value = "";
                        return;
                      }

                      loadFromUrl(tmp_url);
                      setState((prev: any) => ({ ...prev, qst_image: tmp_url }))


                    }
                    }
                  />

                  {((state.qst_image)) ? <img src={state.qst_image} max-width="100%" max-height="80%" className="col-12 mt-3 rounded  mx-auto d-block" alt="..."></img> : null}

                  {state.msg !== '' ? <div>
                    {state.msg}
                  </div> : null}
                </div>
              </div>
            ) : state.mode === "examMode" ||
              state.mode === "gradingModeTeacher" ||
              state.mode === "gradingModeStudent" ? (
              <div
                className="bg-white w-100 text-dark rounded align-items-center p-3"
                dangerouslySetInnerHTML={{ __html: state.header }}
              >
              </div>
            ) : (
              <div></div>
            )}
          </div>

          {state.mode === "editingMode" ? (
            <DifficultLevel
              difficult={state.difficult}
              cognitive={state.cognitive}
              onChangeDifficult={(difficult: any) => { setState((prev: any) => ({ ...prev, difficult: difficult })) }}
              onChangeCognitive={(cognitive: any) => { setState((prev: any) => ({ ...prev, cognitive: cognitive })) }}
            />

          ) : null}
          <div className="col-12 row justify-content-center align-items-center">
            {state.mode === "examMode" ? (
              <>
                <div className="col-12 w-100 mt-3 row justify-content-center align-items-center">

                  {state.qst_image_after_drawing?.includes("https://") ? (
                    <div></div>
                  ) : (
                    <ReactPainter
                      key={state.resetCanvas}
                      width={1000}
                      height={1000}
                      image={state.qst_image_after_drawing !== "" && state.qst_image_after_drawing != null ? state.qst_image_after_drawing : state.qst_image}
                      render={({ triggerSave, canvas, setColor, setLineWidth, imageDownloadUrl }) => (
                        <div>
                          <div className="m-2 row justify-content-center">
                            <div className="col-4">
                              <button className="pt-3 pb-4 mt-2 btn bg-info text-white btn-block" onClick={() => resetcanvasfunc()}>Reset</button>
                            </div>
                            <div className="col-4">
                              <label htmlFor="line-color">Color</label>
                              <input type="color" className="form-control" id="line-color" onChange={e => setColor(e.target.value)} />
                            </div>
                            <div className="col-4">
                              <label htmlFor="line-width">Line Width</label>
                              <input
                                type="number"
                                id="line-width"
                                defaultValue={5}
                                className="form-control"
                                onChange={e => setLineWidth(Number(e.target.value))}
                              />
                            </div>
                          </div>
                          <div onMouseDown={triggerSave} onMouseLeave={triggerSave} onMouseMove={triggerSave}>{canvas}</div>

                          <button className="p-3 btn bg-info text-white btn-block" onClick={() => { onSaveCanvas(imageDownloadUrl); }}
                            disabled={
                              imageDownloadUrl ? false : true
                            }>Save & submit</button>


                        </div>

                      )}
                    />
                  )}

                </div>
              </>
            ) :
              state.mode === "gradingModeTeacher" ||
                state.mode === "gradingModeStudent" ? (
                <div
                  className="bg-white w-100 text-dark rounded align-items-center p-3"
                >
                  <img src={state.qst_image_after_drawing} className="rounded mx-auto d-block" alt="..."></img>
                </div>
              ) : (
                <div></div>
              )}
            {state.mode === "gradingModeTeacher" ||
              state.mode === "gradingModeStudent" ? (
              <>
                <div className="col-12 row align-items-start align-content-start mx-auto">
                  <label className="align-self-start">Feedback</label>
                  <textarea
                    className="form-control align-self-stretch "
                    name=""
                    id=""
                    value={state.feedback}
                    rows={5}
                    disabled={
                      state.mode === "gradingModeStudent" ? true : false
                    }
                    onChange={(e) => {
                      let feedback = e.target.value;
                      let updatedState = { ...state, feedback }
                      onSaveQuestion(updatedState)
                      setState((prev: any) => ({ ...prev, feedback: feedback }))

                    }
                    }
                  ></textarea>
                </div>

                {state.author_note != "" && state.mode === "gradingModeTeacher" ? (
                  <div className="col-12 row align-items-start align-content-start mx-auto">
                    <label className="align-self-start">Author Note </label>
                    <textarea
                      className="form-control align-self-stretch "
                      name=""
                      id=""
                      value={state.author_note}
                      rows={5}
                      disabled={
                        state.mode === "gradingModeStudent" || state.mode === "gradingModeTeacher" ? true : false
                      }
                      onChange={(e) => {
                        let author_note = e.target.value;
                        setState((prev: any) => ({ ...prev, author_note: author_note }))

                      }
                      }
                    ></textarea>
                  </div>
                ) : null}

              </>
            ) : null}
          </div>

          {state.mode === "editingMode" ? (
            <div className="row col-12 my-5"> Analytical Rubrics :
              <div className="  row  col-12 ">
                <div className="col-6 my-3"  >
                  <label>
                  </label>
                  <textarea className="form-control" onChange={(e: any) => { handleCalibration(e) }}
                    value={state.calibration}
                  ></textarea>
                </div>
              </div>
            </div>
          ) : null}

          {(state.mode == "editingMode" || state.mode == "answeringMode") && <div className="text-center col-12">
            <button
              className="btn btn-warning btn-lg rounded col-3 mt-3"
              onClick={() => onSaveQuestion(state)}

            >
              {" "}
              Save
            </button>
          </div>}
        </div>
      </div>
    </div>
  );

}


export default ImageHighlight